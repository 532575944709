<template>
  <b-card>
    <validation-observer ref="regFormObserver" tag="form">
      <b-list-group class="cursor-move">
        <draggable v-model="fieldsList" tag="div">
          <b-list-group-item
            v-for="item in fieldsList"
            :key="item.id"
            class="d-flex align-items-center justify-content-between flex-wrap"
          >
            <b-col :md="4">
              <b-form-text>
                Field Label
              </b-form-text>
              <b-form-input v-model="item.label" :disabled="item.is_readonly" />
            </b-col>

            <b-col :md="4">
              <b-form-text>
                Field Key
              </b-form-text>
              <b-form-input v-model="item.key" :disabled="true" />
            </b-col>

            <b-col md="1">
              <b-form-text>
                Required
              </b-form-text>
              <b-form-checkbox v-model="item.is_required" switch>
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon"/>
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon"/>
                </span>
              </b-form-checkbox>
            </b-col>

            <b-col md="1">
              <b-form-text>
                Active
              </b-form-text>
              <b-form-checkbox v-model="item.is_active" :disabled="item.is_readonly" switch>
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon"/>
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon"/>
                </span>
              </b-form-checkbox>
            </b-col>

            <feather-icon icon="MoveIcon" class="ml-auto cursor-move" />
          </b-list-group-item>
        </draggable>
      </b-list-group>

      <b-row class="pt-4">
        <b-col cols="12">
          <b-button variant="primary" class="mr-1" @click="handleForm">
            Save changes
          </b-button>
          <b-button variant="outline-secondary" :to="{ name: 'admin-instances-list' }">
            Cancel
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BFormInput,
  BFormText,
  BListGroup,
  BListGroupItem,
  BRow
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import { ValidationObserver } from 'vee-validate'
import {mapGetters} from "vuex";
import store from "@/store";

export default {
  name: 'NewChildFormTab',
  components: {
    BListGroup,
    BListGroupItem,
    draggable,
    BCol,
    BFormCheckbox,
    BCard,
    BButton,
    BFormInput,
    BFormText,
    BRow,
    ValidationObserver,
  },
  data() {
    return {
      fieldsList: [],
    }
  },
  computed: {
    ...mapGetters({
      instance: 'app-instances/getInstance',
      formsList: 'app-instances/getFormsList',
    }),
    isLoading: {
      get() {
        return store.getters['app-instances/getIsLoading']
      },
      set(val) {
        store.commit('app-instances/SET_IS_LOADING', val)
      },
    },
  },
  async created() {
    const form = await this.$store.dispatch('app-instances/fetchNewChildForm', {
      'id': this.instance.id,
    })

    this.fieldsList = form.fields
  },
  methods: {
    handleForm() {
      this.$refs.regFormObserver.validate().then(success => {
        if (success) {
          this.saveForms()
        }
      })
    },
    async saveForms() {
      this.isLoading = true
      try {
        await this.$store.dispatch('app-instances/saveNewChildForm', {
          id: this.instance.id,
          data: this.fieldsList,
        })
        this.$emit('success')
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
